import {
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core'
import { NgTemplateOutlet } from '@angular/common'
import { NzIconDirective } from 'ng-zorro-antd/icon'
import { ɵNzTransitionPatchDirective } from 'ng-zorro-antd/core/transition-patch'
import { NzButtonComponent } from 'ng-zorro-antd/button'
import { NzWaveDirective } from 'ng-zorro-antd/core/wave'

@Component({
  selector: 'aiomed-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
  imports: [
    NgTemplateOutlet,
    NzIconDirective,
    ɵNzTransitionPatchDirective,
    NzButtonComponent,
    NzWaveDirective
  ]
})
export class WarningModalComponent implements OnInit {
  @Output('closeModal') closeModalEmitter = new EventEmitter()
  @Output() saveModalDataEmitter = new EventEmitter()
  @Input({ transform: (value: string | null) => value || 'Warning' }) title:
    | string
    | null = 'Warning'
  @Input({
    transform: (value: string | null) =>
      value || 'Exiting without saving will result in data loss'
  })
  description: string | null = 'Exiting without saving will result in data loss'
  @Input() zIndex?: number
  @Input() closeText: string = 'Leave'
  @Input() saveText: string = 'Save & Close'
  @Input() isSessionExpired: boolean = false
  @Input() isDeleteNotification: boolean = false
  @Input() hideSave: boolean = false
  @Input() isLoading: boolean = false
  @Input() isMobile: boolean = false
  @Input() isNormalTitle: boolean = false
  @Input() width: number | string = 577
  @Input() descriptionTemplate: TemplateRef<unknown> | null
  @Input() descriptionWidth: string = 'auto'
  @Input() iconTemplate: TemplateRef<unknown> | null
  @Input() iconType: string | null
  @Input() iconColor: string | null
  @Input() iconBackground: string | null
  @Input() iconSize: string | null
  @Input() isSaveDisabled: boolean = false
  @Input() isFirstButtonDark: boolean = false
  @Input() isTitleShown: boolean = true
  @Input() isButtonsVertical: boolean = false
  backdropColor = input<string>('')

  constructor() {}

  ngOnInit(): void {}
}
